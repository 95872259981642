.wrapper {
  height: 100%;
  position: relative;
}

.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}

.wrapper:after {
  clear: both;
}

.content-wrapper,
.main-footer {
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 280px;
  z-index: 820;
}

@media (max-width: 767px) {
  .content-wrapper,
  .main-footer {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .main-footer {
    transform: translate(280px, 0);
  }
}

.content-wrapper {
  min-height: 100%;
  background-color: #ecf0f5;
  z-index: 800;
}

.content {
  min-height: 280px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
}

.main-header .navbar {
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 280px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}

.main-header #navbar-search-input.form-control {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}

.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
}

.main-header #navbar-search-input.form-control::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

.main-header #navbar-search-input.form-control:-ms-input-placeholder {
  color: #ccc;
}

.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
  color: #ccc;
}

.main-header .navbar-custom-menu,
.main-header .navbar-right {
  float: right;
}

@media (max-width: 991px) {
  .main-header .navbar-custom-menu a,
  .main-header .navbar-right a {
    color: inherit;
    background: transparent;
  }
}

@media (max-width: 767px) {
  .main-header .navbar-right {
    float: none;
  }

  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px;
  }

  .main-header .navbar-right > li {
    color: inherit;
    border: 0;
  }
}

.main-header .sidebar-toggle {
  float: left;
  background-color: transparent;
  background-image: none;
  padding: 15px 15px;
  font-family: fontAwesome;
}

.main-header .sidebar-toggle:before {
  content: "\f0c9";
}

.main-header .sidebar-toggle:hover {
  color: #fff;
}

.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
  background: transparent;
}

.main-header .sidebar-toggle .icon-bar {
  display: none;
}

.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}

.main-header .navbar .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}

.main-header .logo {
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 280px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}

.main-header .logo .logo-lg {
  display: block;
}

.main-header .logo .logo-mini {
  display: none;
}

.main-header .navbar-brand {
  color: #fff;
}

.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}

@media (max-width: 767px) {
  .main-header {
    position: relative;
  }

  .main-header .logo,
  .main-header .navbar {
    width: 100%;
    float: none;
  }

  .main-header .navbar {
    margin: 0;
  }

  .main-header .navbar-custom-menu {
    float: right;
  }
}

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 280px;
  z-index: 810;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .main-sidebar {
    transform: translate(-280px, 0);
  }
}

@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar {
    transform: translate(-280px, 0);
  }
}

@media (max-width: 767px) {
  .sidebar-open .main-sidebar {
    transform: translate(0, 0);
  }
}

.sidebar {
  padding-bottom: 10px;
}

.sidebar-form input:focus {
  border-color: transparent;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  display: block;
}

.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 20px;
}

.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}

.sidebar-menu > li .badge {
  margin-top: 3px;
}

.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}

.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  transition: transform 0.5s ease;
}

.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}

.sidebar-menu .menu-open > a > .fa-angle-left,
.sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-left {
  transform: rotate(-90deg);
}

.sidebar-menu .active > .treeview-menu {
  display: block;
}

@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar {
    transform: translate(0, 0);
    width: 50px !important;
    z-index: 850;
  }

  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative;
  }

  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }

  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 4px;
  }

  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 0;
  }

  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-right-radius: 4px;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }

  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }

  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
  }

  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }

  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > span:not(.pull-right),
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu {
  display: block !important;
  position: absolute;
  width: 280px;
  left: 50px;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > span {
  top: 0;
  margin-left: -3px;
  padding: 12px 5px 12px 20px;
  background-color: inherit;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > .pull-right-container {
  position: relative !important;
  float: right;
  width: auto !important;
  left: 180px !important;
  top: -22px !important;
  z-index: 900;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > .pull-right-container
  > .label:not(:first-of-type) {
  display: none;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu {
  top: 46px;
  margin-left: 0;
}

.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-menu:hover {
  overflow: visible;
}

.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}

.sidebar-menu li > a {
  position: relative;
}

.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
  color: #444;
  background: #f7f7f7;
}

.treeview-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0 0 0 5px;
}

.treeview-menu .treeview-menu {
  padding-left: 20px;
}

.treeview-menu > li {
  margin: 0;
}

.treeview-menu > li > a {
  padding: 5px 5px 5px 50px;
  display: block;
  font-size: 14px;
}

.treeview-menu > li > a > .fa,
.treeview-menu > li > a > .glyphicon,
.treeview-menu > li > a > .ion {
  width: 20px;
}

.treeview-menu > li > a > .pull-right-container > .fa-angle-left,
.treeview-menu > li > a > .pull-right-container > .fa-angle-down,
.treeview-menu > li > a > .fa-angle-left,
.treeview-menu > li > a > .fa-angle-down {
  width: auto;
}

@media print {
  .no-print,
  .main-sidebar,
  .left-side,
  .main-header,
  .content-header {
    display: none !important;
  }

  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0 !important;
    min-height: 0 !important;
    transform: translate(0, 0) !important;
  }

  .table-responsive {
    overflow: auto;
  }

  .table-responsive > .table tr th,
  .table-responsive > .table tr td {
    white-space: normal !important;
  }
}
